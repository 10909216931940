import { root } from './Text.styles'
import { ITextProps } from './Text.types'

const Text = ({
  alignment = 'inherit',
  color = 'inherit',
  tag: Tag = 'div',
  children,
  margin,
  size,
  fontWeight,
  styles
}: ITextProps) => (
  <Tag
    data-testid='text'
    css={root({
      fontWeight,
      alignment,
      color,
      margin,
      size,
      styles
    })}
  >
    {children}
  </Tag>
)

export default Text
