import Script from 'next/script'

const Zendesk = () => {
  if (process.env.NEXT_PUBLIC_DISABLE_ZENDESK === 'true') {
    return null
  }

  return (
    <Script
      id='ze-snippet'
      src='https://static.zdassets.com/ekr/snippet.js?key=bd431e19-a307-4393-96dd-eb73de8cc014'
    />
  )
}
export default Zendesk
