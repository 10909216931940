import SeoBoundary from 'lib/boundaries/SeoBoundary'
import { IDefaultLayout } from 'lib/layouts/DefaultLayout/types'
import Button from 'lib/ui/Button/v2'
import AlternativePaymentsLogo from 'lib/ui/Icon'
import Text from 'lib/ui/Text'
import Image from 'next/image'

import * as css from './styles'

interface IErrorLayout extends Pick<IDefaultLayout, 'headline' | 'title'> {
  message?: React.ReactNode
  handleTryAgain: () => void
}
const ErrorLayout = ({
  headline = 'Sorry',
  message = 'You do not have access to this resource or this page does not exist.',
  title = 'Alternative Payments - Page does not exists',
  handleTryAgain
}: IErrorLayout) => (
  <SeoBoundary title={title}>
    <div data-testid='error-page' css={css.body}>
      <div css={css.messageWrapper}>
        <div css={css.logo}>
          <AlternativePaymentsLogo
            name='alternativePaymentsLogo'
            width={15}
            height={5}
            viewBox='0 0 170 35'
            color='black'
          />
        </div>
        <Text styles={css.title}>{headline}</Text>
        <Text styles={css.message}>{message}</Text>
        <Button styles={css.button} onClick={handleTryAgain}>
          Try again
        </Button>
      </div>
      <div css={css.imageContainer}>
        <div css={css.imageWrapper}>
          <Image
            src='/error-hero-image.png'
            alt={title}
            fill
            objectFit='contain'
          />
        </div>
      </div>
    </div>
  </SeoBoundary>
)

export default ErrorLayout
