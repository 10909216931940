import { ReactNode } from 'react'

import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

interface ISeoBoundary {
  title: string
  children: ReactNode
}

const SeoBoundary = ({ children, title }: ISeoBoundary) => {
  const { asPath } = useRouter()
  return (
    <>
      <NextSeo
        title={
          title ||
          'Alternative - Reimagining Financial Services With B2B Payment Solutions'
        }
        canonical={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${asPath}`}
      />
      {children}
    </>
  )
}

export default SeoBoundary
