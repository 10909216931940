import { CSSProperties } from 'react'

import { Interpolation, Theme } from '@emotion/react'
import { ITheme } from 'utils/types'

import theme from '../theme'

type ICSS<T> = (theme: ITheme) => T

export const createStyles = <P extends CSSProperties | Interpolation<Theme>>(
  cssFunction: ICSS<P>
): P => cssFunction(theme)
