import React, { ReactNode, Suspense } from 'react'

import ErrorLayout from 'lib/layouts/ErrorLayout'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Caught error:', error, errorInfo)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <Suspense fallback={null}>
        <ErrorLayout
          message='An unknown error occurred, please try again.'
          title='An error occurred'
          handleTryAgain={() => this.setState({ hasError: false })}
        />
      </Suspense>
    )
  }
}

export default ErrorBoundary
