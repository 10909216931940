import Info from '@carbon/icons-react/lib/InformationFilled'

import * as css from './styles'

const ComponentUnderMaintenance = () => (
  <div css={css.container}>
    <div css={css.icon}>
      <div css={css.iconWrapper}>
        <Info size={24} />
      </div>
    </div>
    <div css={css.message}>
      <p css={css.title}>Under maintenance</p>
      <p>
        We're doing a quick maintenance on this section of the app, it'll be
        back in a few minutes!
      </p>
    </div>
  </div>
)

export default ComponentUnderMaintenance
