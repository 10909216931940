import { formatNumber } from 'utils/numbers'
import { ISpacing } from 'utils/types'

const getSize = (...sizes: number[]) =>
  [...sizes, 0].find(size => typeof size !== 'undefined')

/**
 * Calculate spacing defaulting to using 16px (1.6rem) increments to match grid design
 * @param size the size amount applied to space. By default 1 unit = 16px, 0.25 = 4px
 * @param unit unit of measurement, defaults to rem but can be overriden to use px
 * @param base the amount to multiply by, defaults to 1.6
 * @returns string value to by used as style measurement (ie 1.6rem)
 */
export const spacing: ISpacing = (size, unit = 'rem', base = 1.6) => {
  if (typeof size === 'undefined') {
    return undefined
  } else if (Array.isArray(size)) {
    return [
      spacing(getSize(size[0]), unit, base),
      spacing(getSize(size[1]), unit, base),
      spacing(getSize(size[2], size[0]), unit, base),
      spacing(getSize(size[3], size[1]), unit, base)
    ].join(' ')
  } else {
    return `${formatNumber({ amount: size * base, places: 2 })}${unit}`
  }
}
