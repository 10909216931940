import { ITypography } from 'utils/types/theme.types'

import colors from './colors'
import { breakpoints, mediaQuery } from './mediaQuery'
import {
  buttonFonts,
  buttonSizes,
  fontSizes,
  headingSizes,
  inputFonts,
  inputLabels,
  inputSizes
} from './sizes'
import { spacing } from './spacing'
import { formatNumber } from '../numbers'
import {
  IAlignments,
  IShadows,
  ISize,
  ISizes,
  ITheme,
  ITreatments
} from '../types'

/* New design system components*/
const typography: ITypography = {
  heading: {
    sizes: {
      xl: '64px',
      lg: '48px',
      md: '32px',
      sm: '24px'
    }
  },
  title: {
    sizes: {
      xl: '24px',
      lg: '20px'
    }
  },
  body: {
    sizes: {
      xl: '18px',
      lg: '16px',
      md: '14px',
      sm: '12px'
    }
  },
  caption: {
    sizes: {
      md: '10px'
    }
  },
  link: {
    sizes: {
      lg: '14px',
      md: '12px',
      sm: '10px'
    }
  }
}

/**
 * Convert number to string with rem value for style sizing
 */
export const size: ISize = (exponent = 1, scale = 1) =>
  `${formatNumber({ amount: scale * exponent, places: 2 })}rem`

export const zIndexStack = 2147483002 // setting z-index this high because it's Intercom's z-index + 1

const effects = {
  hide: {
    border: '0px',
    clip: 'rect(0px, 0px, 0px, 0px)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: '0px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute'
  },
  grow: {
    transition: 'ease-out .25s',
    backfaceVisibility: 'hidden',
    '&:hover, &:focus': {
      transform: 'scale(1.025)'
    }
  },
  shrink: {
    transition: 'ease-in .25s',
    backfaceVisibility: 'hidden',
    '&:hover, &:focus': {
      transform: 'scale(0.975)'
    }
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  overlay: {
    background: `rgba(22, 24, 46, 0.75)`,
    transition: 'background 0.25s'
  }
}

const shadows: IShadows = {
  blur: '0px 4px 58px 4px rgba(0, 0, 0, 0.21)',
  soft: '0px 4px 20px rgba(216, 216, 216, 0.25)',
  subtle: '2px 2px 4px 2px rgba(222, 222, 229, 0.38)',
  light: '5px 5px 15px rgba(0, 0, 0, 0.25)',
  medium: '5px 5px 20px 5px rgba(0, 0, 0, 0.5)',
  dark: '5px 5px 30px 5px rgba(0, 0, 0, 0.75)',
  none: 'none'
}

const radii: ISizes = {
  sm: size(0.4),
  md: size(0.6),
  lg: size(0.8),
  xl: size(1.2),
  xxl: size(1.6)
}

const treatments: ITreatments = {
  body: {
    fontFamily: '"Inter", sans-serif',
    fontSize: fontSizes.md,
    fontWeight: 400,
    lineHeight: 1.5,
    color: colors.grey30
  },
  heading: {
    marginBottom: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25
  },
  button: {
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer'
  },
  container: {
    maxWidth: size(64)
  },
  siteContainer: {
    maxWidth: size(128)
  },
  links: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  focus: {
    '&:focus': {
      borderColor: colors.primary
    },
    '&:focus-visible': {
      outlineColor: colors.primary
    }
  },
  placeholder: {
    color: colors.purple10,
    opacity: 0.7
  },
  disabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'not-allowed'
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stackTop: {
    zIndex: zIndexStack // setting z-index override because it's Intercom's z-index + 1
  }
}

const alignments: IAlignments = {
  left: 'left',
  right: 'right',
  center: 'center',
  inherit: 'inherit',
  start: 'flex-start',
  end: 'flex-end',
  stretch: 'stretch'
}

const justifications: IAlignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  start: 'flex-start',
  end: 'flex-end',
  inherit: 'inherit',
  baseline: 'baseline',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly'
}

export const theme: ITheme = {
  alignments,
  breakpoints,
  buttons: {
    fonts: buttonFonts,
    radii,
    sizes: buttonSizes
  },
  colors,
  effects,
  fontSizes,
  headingSizes,
  inputs: {
    fonts: inputFonts,
    labels: inputLabels,
    radii,
    sizes: inputSizes
  },
  justifications,
  mediaQuery,
  radii,
  shadows,
  size,
  spacing,
  treatments,
  typography
}

export default theme
