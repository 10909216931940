import Box from 'lib/ui/Box'
import MaintenaceImage from 'lib/ui/Box/MaintenaceImage'
import Heading from 'lib/ui/Heading'
import Icon from 'lib/ui/Icon'
import Text from 'lib/ui/Text'

import * as css from './styles'

const MaintenanceMode = () => (
  <Box alignment='center' fullHeight={true}>
    <Icon
      name='alternativePaymentsLogo'
      width={14}
      height={4}
      viewBox='0 0 170 35'
      color='black'
    />
    <div css={css.imageContainer}>
      <div css={css.imageWrapper}>
        <MaintenaceImage />
      </div>
      <div css={css.subtext}>
        <Heading alignment='center' color='grey25' tag='h1' size='md'>
          Under maintenance
        </Heading>
        <Text color='surface400' size='md' margin={[0, 0, 2]}>
          We're doing a quick maintenance on this section of the app. Please,
          come back in a few minutes.
        </Text>
      </div>
    </div>
  </Box>
)

export default MaintenanceMode
