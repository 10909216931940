import Color from 'color'

import colors from '../theme/colors'
import { ColorProps } from '../types'

export const createBrandColor = (
  partnerColor: string | undefined
): ColorProps => {
  const hasCustomBrandColor = partnerColor && partnerColor !== ''

  const primaryColor = hasCustomBrandColor
    ? Color(partnerColor)
    : Color(colors.primary)

  const isDark = primaryColor.isDark()
  const hasLowContrastToWhite = primaryColor.contrast(Color('white')) < 3

  return {
    color: primaryColor,
    hex: primaryColor.hex(),
    hsl: primaryColor.hsl(),
    hasCustomBrandColor,
    isDark,
    hasLowContrastToWhite
  }
}
