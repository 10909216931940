import { ApolloProvider } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import ErrorBoundary from 'lib/boundaries/ErrorBoundary'
import MaintenanceMode from 'lib/layouts/MaintenanceMode'
import Root from 'lib/layouts/RootLayout'
import 'utils/css/reset.css'
import SegmentScript from 'lib/utils/SegmentScript'
import Seo from 'lib/utils/Seo'
import Zendesk from 'lib/utils/Zendesk'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import setPageName from 'utils/analytics'

import packageJson from '../package.json'
import PartnerProvider from '@/hooks/providers/PartnerProvider'
import { authClient } from '@/utils/apollo'

const Toast = dynamic(() => import('lib/ui/Toast'), {
  ssr: false
})

declare global {
  interface Window {
    analytics: any
  }
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', () => {
  window?.analytics?.page(setPageName({ route: Router.route, app: 'Auth' }))
})

{
  process.env.NEXT_PUBLIC_DATADOG_ID &&
    process.env.NEXT_PUBLIC_DATADOG_ID !== '' &&
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
      site: 'datadoghq.com',
      service: 'auth-portal',
      version: packageJson.version,
      traceSampleRate: 100,
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      allowedTracingUrls: [process.env.NEXT_PUBLIC_GRAPH_URI]
    })

  datadogRum.startSessionReplayRecording()
}

function AuthApp({ Component, pageProps }: AppProps) {
  const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY
  const enableMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true'
  return (
    <ApolloProvider client={authClient}>
      <PartnerProvider>
        <Seo />
        <SegmentScript app='Auth' segmentKey={segmentKey} />
        <Toast />
        <Root>
          {enableMaintenance ? (
            <MaintenanceMode />
          ) : (
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          )}
        </Root>
        <Zendesk />
      </PartnerProvider>
    </ApolloProvider>
  )
}

export default AuthApp
