import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { httpLink, retryLink, ssrErrorLink } from 'utils/apollo'

/* SSR shares cache in server so we never wanna use it */
export const authSSRClient = new ApolloClient({
  link: from([ssrErrorLink, httpLink]),
  cache: new InMemoryCache(),
  ssrMode: true,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const authClient = new ApolloClient({
  link: from([retryLink, httpLink]),
  cache: new InMemoryCache(),
  ssrMode: false,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  }
})
