import { createHttpLink, ObservableQuery, FetchResult } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { Observable } from '@apollo/client/utilities'
import { datadogRum } from '@datadog/browser-rum'
import { GraphQLError } from 'graphql'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import { AppCookie, getCookieExpires } from 'utils/cookies'

type DelayRefetch = {
  observableQuery: ObservableQuery
  ms?: number
}

const wait = (ms: number) => new Promise(res => setTimeout(res, ms))

export const delayRefetchedQuery = async ({
  observableQuery,
  ms = 1000
}: DelayRefetch) => {
  await wait(ms)
  observableQuery.refetch()
}

/**
 *
 * Handles GraphQL and network errors for Apollo Client, with support for authentication refresh and custom error handling.
 *
 * @param {ErrorResponse} errorResponse - The error response object from Apollo Client.
 * @param {Function} refreshFunction - A function that returns a Promise to refresh the authentication token.
 * @param {Function} customError - An optional function for custom error handling.
 * @param {GraphQLError} customError.error - The GraphQL error object to be handled.
 * @param {Function} customError.stop - A function to stop the default error handling after the custom error handling is done.
 *
 * @description
 * This function handles various types of errors that can occur during GraphQL operations. It also integrates with toast notifications for user feedback and Datadog RUM for error logging.
 *
 * @example
 * const paymentsErrorLink = onError(errors =>
 * handleErrors(errors, oauth2RefreshPayments, (error, stop) => {
 *   const { extensions, path } = error
 *   if (
 *     (extensions?.status as number) === 404 &&
 *     (path || [])[0] === 'GetUserInfo'
 *   ) {
 *     // if user is not found we don't want to show an error
 *     stop()
 *   }
 *  })
 * )
 *
 */
export const handleErrors = (
  { graphQLErrors, networkError, operation, forward }: ErrorResponse,
  refreshFunction: () => Promise<void>,
  customError?: (error: GraphQLError, stop: () => void) => void
) => {
  if (graphQLErrors && graphQLErrors?.length > 0) {
    for (const [index, error] of graphQLErrors.entries()) {
      const { extensions } = error
      // only process first error to avoid multiple toasts
      if (index === 0 && !networkError) {
        if (
          (extensions?.status as number) === 401 ||
          (extensions?.status as number) === 403
        ) {
          const observable = new Observable<FetchResult<Record<string, any>>>(
            observer => {
              const retryPreviousRequest = () => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                }

                forward(operation).subscribe(subscriber)
              }

              const handleUnauthorizedRequest = async () => {
                try {
                  // the first request locks, refresh then retries request
                  // consecutive requests wait for refresh then retry request
                  await refreshLock(refreshFunction)
                  retryPreviousRequest()
                } catch (err) {
                  observer.error(err)
                }
              }

              handleUnauthorizedRequest()
            }
          )

          return observable
        }

        // custom error handling
        let stopHandlingError = false
        if (customError) {
          customError(error, () => (stopHandlingError = true))
        }
        if (stopHandlingError) {
          return
        }

        if (
          extensions?.status === 500 &&
          operation.getContext().fallbackCachedData
        ) {
          return datadogRum.addAction('apiError', { error, operation })
        }
        if ((extensions?.status as number) < 500) {
          toast.error(error.message || 'An unknown error occurred', {
            id: 'error'
          })
          return datadogRum.addAction('apiError', { error, operation })
        }

        datadogRum.addAction('serverError', { error, operation })
        toast.error(error.message || 'An unknown error occurred', {
          id: 'error'
        })
      }
    }
  }

  if (networkError) {
    datadogRum.addAction('networkError', { networkError, operation })
  }
}

const handleSSRErrors = ({
  graphQLErrors,
  networkError,
  operation
}: ErrorResponse) => {
  if (graphQLErrors && graphQLErrors?.length > 0) {
    for (const [index, error] of graphQLErrors.entries()) {
      // only process first error to avoid multiple toasts
      if (index === 0 && !networkError) {
        // if error for refresh request, refresh token is not valid anymore
        if (operation.operationName === 'OAuth2Refresh') {
          datadogRum.addAction('sessionExpired', { error, operation })
          toast.error('Your session has expired. Please log in again.')

          const isImpersonating =
            Cookies.get(AppCookie.Impersonating) === 'true'

          window.location.href = isImpersonating
            ? '/logout-impersonation'
            : '/logout'
        }

        datadogRum.addAction('serverError', { error, operation })
        toast.error(error.message || 'An unknown error occurred')
      }
    }
  }

  if (networkError) {
    datadogRum.addAction('networkError', { networkError, operation })
  }
}

const refreshLock = async (refreshFunction: () => Promise<void>) => {
  const isRefreshLocked = Cookies.get(AppCookie.RefreshLock)

  try {
    if (!isRefreshLocked) {
      Cookies.set(AppCookie.RefreshLock, 'true', {
        expires: getCookieExpires(AppCookie.RefreshLock),
        httpOnly: false
      })
      await refreshFunction()
      Cookies.remove(AppCookie.RefreshLock)
    } else {
      await waitForRefreshCompletion()
    }
  } catch (err) {
    Cookies.remove(AppCookie.RefreshLock)
    throw new GraphQLError(err)
  }
}

const waitForRefreshCompletion = () =>
  new Promise<void>(resolve => {
    const interval = setInterval(() => {
      const isRefreshLocked = Cookies.get(AppCookie.RefreshLock)

      if (!isRefreshLocked) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })

export const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (error && count < 5) {
      return true
    }
    if (error) {
      toast.error(error?.message || 'An unknown error occurred')
      datadogRum.addAction('retryError', { error, operation, count })
    }
  }
})

export const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPH_URI
})

export const ssrErrorLink = onError(errors => handleSSRErrors(errors))
