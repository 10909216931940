import { IPaddings, ISizes } from 'utils/types'
/**
 * 1rem = 10px because :root set to 62.5%
 */
export const fontSizes: ISizes = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.8rem',
  lg: '2.4rem',
  xl: '3.8rem'
}

export const headingSizes: ISizes = {
  xs: '2rem',
  sm: '2.4rem',
  md: '3.2rem',
  lg: '4.2rem',
  xl: '5.6rem'
}

export const inputSizes: ISizes = {
  sm: '2.4rem',
  md: '3.2rem',
  lg: '4.8rem',
  xl: '6.4rem'
}

export const inputFonts: ISizes = {
  xs: '1rem',
  sm: '1.2rem',
  md: '1.4rem',
  lg: '1.85rem',
  xl: '2.4rem'
}

export const inputLabels: ISizes = {
  sm: '1.2rem',
  md: '1.4rem',
  lg: '1.4rem',
  xl: '1.8rem'
}

export const buttonFonts: ISizes = {
  sm: '1.2rem',
  md: '1.4rem',
  lg: '1.8rem',
  xl: '2.4rem'
}

/*
 * height factors in font size,
 * ie xl 2rem padding will create 64px height button
 */
export const buttonSizes: IPaddings = {
  sm: [0.5, 1.6, 0.5, 1.6],
  md: [0.9, 2.4, 0.9, 2.4],
  lg: [1.5, 3.2, 1.5, 3.2],
  xl: [2, 3.2, 2, 3.2]
}
