export interface ISegmentScript {
  app: 'Payments Portal' | 'Partner Portal' | 'Website' | 'Admin' | 'Auth'
  segmentKey: string | null | undefined
}
interface ISetPageName extends Pick<ISegmentScript, 'app'> {
  route: string
}

const setPageName = ({ route, app }: ISetPageName) => {
  const defaultName = `${app}: ${route}`
  switch (route) {
    case '/': {
      return `${app}: Login`
    }
    default:
      return defaultName
  }
}

interface IHandleError {
  error: Error
  message: string
  data?: any
}

export const handleError = ({ error, message, data = {} }: IHandleError) => {
  if (window?.analytics) {
    window.analytics.track(message, {
      error: error?.message || 'Unknown error',
      ...data
    })
  }
}

interface IHandleTrack {
  error?: Error
  message: string
  data?: any
}

export const handleSegmentTracking = ({
  message,
  data = {},
  error
}: IHandleTrack) => {
  if (window.analytics) {
    window.analytics.track(message, {
      error,
      ...data
    })
  }
}

export default setPageName
