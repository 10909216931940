import { forwardRef, ForwardedRef } from 'react'

import Link from 'next/link'

import root, { loader } from './Button.styles'
import { IButtonProps } from './Button.types'
import Dots from '../Animations/Loading/Dots'

const ButtonContent = ({ children, loading }) => (
  <>
    {loading && (
      <div css={{ ...loader }}>
        <Dots color='grey30' />
      </div>
    )}
    <span
      css={{
        visibility: loading ? 'hidden' : 'visible',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit'
      }}
    >
      {children}
    </span>
  </>
)

const ButtonRef = (
  {
    buttonSize = 'lg',
    display = 'inline-block',
    variant = 'primary',
    type = 'button',
    textSize,
    children,
    disabled,
    href,
    form,
    id,
    loading,
    name,
    newTab,
    onClick,
    value,
    brandColor,
    iconPlacement,
    padding,
    styles
  }: IButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const rootProps = {
    brandColor,
    buttonSize,
    textSize,
    display,
    iconPlacement,
    loading,
    padding,
    styles,
    variant
  }

  if (href) {
    const isInternal = href.indexOf(process.env.NEXT_PUBLIC_APP_DOMAIN) !== -1
    const linkType =
      href.indexOf('://') === -1 && href.indexOf('www') === -1
        ? 'internalLink'
        : 'externalLink'
    if (linkType === 'internalLink' || isInternal) {
      return (
        <Link href={href} passHref css={root(rootProps)} id={id}>
          <ButtonContent loading={loading}>{children}</ButtonContent>
        </Link>
      )
    }
    return (
      <a
        id={id}
        css={root(rootProps)}
        href={href}
        target={newTab ? '_blank' : '_self'}
        rel='noreferrer'
      >
        <ButtonContent loading={loading}>{children}</ButtonContent>
      </a>
    )
  }
  return (
    <button
      ref={ref}
      id={id}
      form={form}
      css={root(rootProps)}
      disabled={disabled || loading}
      name={name}
      type={type}
      onClick={onClick}
      value={value}
    >
      <ButtonContent loading={loading}>{children}</ButtonContent>
    </button>
  )
}

const Button = forwardRef(ButtonRef)

export default Button
