import { IBreakpoints, IMediaQuery } from 'utils/types'
export type { IBreakpoints, IMediaQuery }
export const breakpoints: IBreakpoints = {
  xs: '350px',
  sm: '568px',
  md: '768px',
  lg: '968px',
  desktop: '1150px',
  desktopLg: '1359px',
  xl: '1440px',
  xxl: '1660px'
}

export const mediaQuery: IMediaQuery = (size = 'sm', query = 'min-width') =>
  `@media (${query}: ${breakpoints[size]})`
