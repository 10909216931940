import theme from 'utils/theme'

import { IBox, IBoxOuterStyles } from './Box.types'

const { alignments, colors, radii, size, spacing, treatments, shadows } = theme

export const outerStyles = ({
  outerColor,
  shadow,
  styles
}: IBoxOuterStyles) => {
  const defaultOuter = {
    backgroundColor: outerColor && (colors[outerColor] || outerColor),
    boxShadow: shadow && shadows[shadow]
  }
  return { ...defaultOuter, ...styles?.outer }
}

export const innerStyles = ({
  alignment,
  background,
  borderRadius,
  foreground,
  fullHeight,
  margin,
  padding,
  styles,
  width
}: IBox) => {
  const defaultInner = {
    textAlign: alignment && (alignments[alignment] as any),
    backgroundColor: background && (colors[background] || background),
    borderRadius: borderRadius && radii[borderRadius],
    color: foreground && colors[foreground],
    maxWidth: width ? size(width) : (treatments.container as any),
    margin: !margin ? 'auto' : spacing(margin),
    minHeight: fullHeight && '100vh',
    padding: spacing(padding)
  }

  return { ...defaultInner, ...styles?.inner }
}
