import { createStyles } from 'utils/css'

export const container = createStyles(({ colors }) => ({
  backgroundColor: colors.surface50,
  borderLeft: `4px solid ${colors.primary200}`,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: '16px',
  padding: '16px 24px',
  borderRadius: '4px',
  boxShadow: '0px 4px 4px -1px rgba(36, 104, 147, 0.04)'
}))

export const icon = createStyles(({ colors }) => ({
  color: colors.light
}))

export const iconWrapper = createStyles(({ spacing, colors }) => ({
  backgroundColor: colors.primary100,
  borderRadius: '50%',
  display: 'flex',
  padding: spacing(0.5)
}))

export const message = createStyles(({ colors, typography }) => ({
  color: colors.surface400,
  fontSize: typography.body.sizes.md,
  '& p': {
    margin: 0
  }
}))

export const title = createStyles(({ typography, colors }) => ({
  color: colors.primary200,
  fontSize: typography.title.sizes.lg,
  fontWeight: 600
}))

export const imageContainer = createStyles(() => ({
  width: '100%',
  height: '90vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}))

export const imageWrapper = createStyles(() => ({
  flex: 1,
  alignContent: 'end',
  minWidth: '300px',
  maxWidth: '335px',
  width: '100%',
  paddingTop: '6vh'
}))

export const subtext = createStyles(() => ({
  flex: 1,
  alignContent: 'start',
  minWidth: '300px',
  maxWidth: '420px',
  width: '100%',
  paddingTop: '6vh'
}))
