import { ReactNode } from 'react'

import { Global } from '@emotion/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { createStyles } from 'utils/css'

const css = (isPrintMode: boolean) =>
  createStyles(({ spacing, treatments }) => ({
    overflow: isPrintMode ? 'unset' : 'auto',
    margin: 0,
    height: '100%',
    '& p': {
      marginBottom: spacing(0.75)
    },
    '& strong': {
      fontWeight: 600
    },
    '& em': {
      fontStyle: 'italic'
    },
    '& a': {
      ...treatments.links
    },
    ...treatments.body
  }))

const Root = ({ children }: { children: ReactNode }) => {
  const { query } = useRouter()
  const printMode = query.print === 'true'

  return (
    <>
      <Head>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      <Global
        styles={{
          //prevent scrolling out of layout
          body: { overflow: 'hidden' },
          // allow for sibling elements to stretch height side by side
          'html, body, #__next': { height: '100%', margin: 'auto' }
        }}
      />

      <div id='portal' css={css(printMode)}>
        {children}
      </div>
    </>
  )
}

export default Root
