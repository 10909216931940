import { createStyles } from 'utils/css'

import { IIconStyles } from './Icon.types'

export const root = ({
  block,
  color,
  height,
  rotate,
  width,
  styles
}: IIconStyles) =>
  createStyles(({ colors, size }) => ({
    display: block ? 'block' : 'inline-block',
    fill: color ? colors[color] : 'currentColor',
    width: size(width),
    height: size(height),
    transition: 'transform 0.150ms ease',
    transform: `rotate(${rotate}deg)`,
    ...styles
  }))
