import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'

const Seo = () => {
  const { asPath } = useRouter()
  return (
    <DefaultSeo
      canonical={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${asPath}`}
      title='Alternative Payments - Your Payment Portal for Payment Acceptance'
      description='Alternative Payments is a B2B payments and checkout infrastructure company, providing a domain that customers may visit and view outstanding / due invoices anytime and pay via financing, ACH and credit cards.  The platform integrates directly with a company’s invoicing software and reconciles all transactions to its accounting software, providing a fully-integrated approach that does not exist, outside of custom ERP applications.'
      openGraph={{
        type: 'website',
        images: [
          {
            url: 'https://images.prismic.io/alternative-co/d71f74dc-769b-4ccc-a28b-a2578caa9d32_alternative-purple.svg?w=1200&h=244',
            alt: 'Alternative Logo',
            width: 1200,
            height: 244
          }
        ]
      }}
    />
  )
}

export default Seo
