import * as css from './Box.styles'
import { IBox } from './Box.types'

const Box = ({
  tag: Tag = 'div',
  padding = 1,
  children,
  outerColor,
  styles,
  shadow,
  alignment,
  background,
  borderRadius,
  foreground,
  fullHeight,
  margin,
  width,
  onClick,
  role,
  tabIndex
}: IBox) => {
  const handleKeyDown = event => {
    if (!onClick) return

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      onClick(event)
    }
  }
  return (
    <Tag
      data-testid='box-outer'
      css={css.outerStyles({ outerColor, shadow, styles })}
    >
      <div
        data-testid='box-inner'
        css={css.innerStyles({
          alignment,
          background,
          borderRadius,
          foreground,
          fullHeight,
          margin,
          padding,
          styles,
          width
        })}
        className='Box-root'
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role={role}
        tabIndex={tabIndex}
      >
        {children}
      </div>
    </Tag>
  )
}

export default Box
