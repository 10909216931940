export const formatRate = ({
  amount,
  places = 0
}: {
  amount: number
  places?: number
}) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: places,
    maximumFractionDigits: places
  }).format(amount)

export const formatNumber = ({
  amount,
  places = 0,
  notation
}: {
  amount: number
  places?: number
  notation?: 'compact' | 'standard'
}) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: amount > 999999 ? Math.max(1, places) : places,
    notation: !notation ? (amount > 999999 ? 'compact' : 'standard') : notation
  }).format(amount)

export const formatCurrency = ({
  amount,
  notation,
  showCents = true
}: {
  amount: number
  notation?: 'compact' | 'standard'
  showCents?: boolean
}) => {
  const isLargeNumber = amount > 999999
  const isWholeNumber = amount % 1 === 0

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents
      ? 2
      : isWholeNumber || isLargeNumber
      ? 0
      : 2,
    maximumFractionDigits: 2,
    notation: !notation ? (isLargeNumber ? 'compact' : 'standard') : notation
  }).format(amount)
}
