import { IColors } from 'utils/types'

const colors: IColors = {
  transparent: 'transparent',
  inherit: 'inherit',
  light: '#fff',
  dark: '#000',
  grey5: '#F9F9F9',
  grey10: '#EFEFF2',
  grey15: '#DEDEE5',
  grey: '#868896',
  grey20: '#868896',
  grey25: '#414354',
  grey30: '#2C2929',
  genericSoft: '#F7FCFF',
  genericSoftDarker: '#D5E3ED',
  purple5: '#ECEDF9',
  purple10: '#868CC1',
  purple15: '#5A54A6',
  purple20: '#413573',
  primary: '#413573',
  purple25: '#2A2E51',
  purple30: '#0A0923',
  danger: '#A32A19',
  success: '#1F631D',
  warning: '#E6C44C',
  black: '#1C1C1C',
  blueBackground: '#E9F0FB',
  blueLight: '#286ACC',
  blue: '#1B53A7',
  blueDark: '#103C7E',
  blueLink: '#0000EE',
  brown: '#785D01',
  greenBackground: '#ECF9EB',
  greenLight: '#2D892A',
  green: '#1F631D',
  greenDark: '#165214',
  green5: '#F7FBF6',
  green10: '#ECF9EB',
  green15: '#D1E9CF',
  green20: '#6BA369',
  green25: '#3A6D39',
  green30: '#185416',
  greenOpac: '#6BA56647',
  yellowBackground: '#FCF8E9',
  yellowLight: '#EDD992',
  yellow: '#E6C44C',
  yellowDark: '#D5AC1A',
  yellow5: '#FFFDF5',
  yellow10: '#FEF8E1',
  yellow15: '#FEEFBE',
  secondary: '#FDE79E',
  yellow20: '#FDE79E',
  yellow25: '#F2BC07',
  yellow30: '#AD8605',
  redBackground: '#FCEBE9',
  redLight: '#FC9183',
  red5: '#FFE5CD',
  red200: '#C92929',
  red: '#A32A19',
  redDark: '#882011',
  gradientLight:
    'linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%);',
  gradientPurple: 'linear-gradient(180deg, #413573 0%, #5A54A6 100%);',
  gradientPurpleDark: 'linear-gradient(180deg, #0A0923 0%, #44307E 100%);',
  shade: 'rgba(0, 0, 0, 0.125)',
  tint: 'rgba(255, 255, 255, 0.25)',
  semiTransparent: 'rgba(255, 255, 255, 0.5)',

  surface50: '#FFFFFF',
  surface100: '#F7F7FA',
  surface200: '#EFEFF2',
  surface300: '#DEDEE5',
  surface400: '#868896',
  surface500: '#414354',
  surface600: '#2C2929',
  surface700: '#141010',

  primary50: '#ECEDF9',
  primary100: '#868CC1',
  primary200: '#5A54A6',
  primary300: '#413573',
  primary400: '#2A2E51',
  primary500: '#0A0923',

  secondary50: '#FFFDF5',
  secondary100: '#FEF8E1',
  secondary200: '#FEEFBE',
  secondary300: '#FDE79E',
  secondary400: '#F2BC07',
  secondary500: '#AD8605',

  danger50: '#FBEAEA',
  danger100: '#E16B6B',
  danger200: '#C92929',
  danger300: '#A92323',
  danger400: '#6E1717',

  success50: '#F6FEFB',
  success100: '#8FCCB5',
  success200: '#4D997C',
  success300: '#1F664B',
  success400: '#053322',

  warning50: '#FFFDFA',
  warning100: '#F9DBB5',
  warning200: '#F2AB53',
  warning300: '#EE9322',
  warning400: '#A6620D',

  information50: '#EBF0FF',
  information100: '#93A1CC',
  information200: '#506299',
  information300: '#213266',
  information400: '#061133',

  yellow02: '#E5D8AC',
  yellow03: '#AD8605',

  orange01: '#F7B787',
  orange02: '#CC9870',
  orange03: '#B26124',

  tag01: '#3F475B',
  tag02: '#75C7C1',
  tag03: '#F6CF84',
  tag04: '#FEB382',
  tag05: '#E38484',
  tag06: '#C1ACE7',
  tag07: '#96A5B6',
  tag08: '#C0D9B7',

  tagText01: '#FAFAFA',
  tagText02: '#152E2C',
  tagText03: '#3D3322',
  tagText04: '#362012',
  tagText05: '#270808',
  tagText06: '#19161E',
  tagText07: '#111720',
  tagText08: '#10160E'
}
export default colors
