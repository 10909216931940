import { ISizes } from 'utils/types'

import * as css from './Heading.styles'
import { IHeading } from './Heading.types'
/**
 * @param size string value represented in rem
 * @returns decrease headings by 65% for mobile screens
 * for xl and lg sizes
 */
export const fontRatio = (rem, size: keyof ISizes) =>
  Number(rem.split('rem')[0]) * (size === 'xl' || size === 'lg' ? 0.65 : 1)

const Heading = ({
  children,
  alignment = 'left',
  color = 'grey30',
  size = 'md',
  tag: Tag = 'h2',
  margin,
  styles
}: IHeading) => (
  <Tag
    css={css.root({
      alignment,
      color,
      margin,
      size,
      styles
    })}
  >
    {children}
  </Tag>
)

export default Heading
