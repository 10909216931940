import { root } from './Icon.styles'
import { IIcon, IPath } from './Icon.types'
import icons from './Icons'

const Icon = ({
  name,
  title,
  viewBox = '0 0 32 32',
  rotate = 0,
  block,
  color,
  height,
  width,
  styles
}: IIcon) => {
  const iconPaths = icons[name] as IPath[]

  if (!iconPaths) return null
  return (
    <svg
      aria-hidden
      className={`alt-icon alt-icon-${name}`}
      data-testid='icon'
      css={root({
        block,
        color,
        height,
        rotate,
        width,
        styles
      })}
      viewBox={viewBox}
      role='img'
    >
      {title && <title>{title}</title>}
      {iconPaths.map((path, i) => (
        <path {...path} key={i} />
      ))}
    </svg>
  )
}

export default Icon
