import { createContext, useContext, useEffect, useMemo } from 'react'

import { ApolloError } from '@apollo/client/errors'
import {
  PartnerWhitelabelFragment,
  useGetPartnerByIdLazyQuery
} from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { createBrandColor } from 'utils/colors'
import { AppCookie } from 'utils/cookies'
import { ColorProps } from 'utils/types'

/**
 * Provider that supplies partner data when the partnerId cookie is provided.
 */

interface IPartnerContext {
  partner: PartnerWhitelabelFragment
  brandColor: ColorProps
  error: ApolloError
  loading: boolean
}

export const PartnerContext = createContext<IPartnerContext>({
  partner: null,
  brandColor: null,
  error: null,
  loading: false
})

const PartnerProvider = ({ children }) => {
  const partnerId = Cookies.get(AppCookie.PartnerIdAuth)

  const [fetchPartner, partnerQuery] = useGetPartnerByIdLazyQuery()

  const value = useMemo(() => {
    const partner = partnerQuery.data?.GetPartnerById

    return {
      partner,
      brandColor: createBrandColor(partner?.paymentsPortalCustomization?.color),
      loading: partnerQuery.loading,
      error: partnerQuery.error
    }
  }, [partnerQuery.data, partnerQuery.loading])

  useEffect(() => {
    if (partnerId) {
      fetchPartner({
        variables: {
          partnerId
        }
      })
    }
  }, [partnerId])

  return (
    <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
  )
}

export const usePartnerContext = () => useContext(PartnerContext)

export default PartnerProvider
